

import {computed, defineComponent, ref} from "vue";
import PaymentInfo from "@/components/new-reservation-manuel/general/PaymentInfo.vue";
import CurType from "@/components/new-reservation-manuel/general/CurType.vue";
import Hotel from "@/components/new-reservation-manuel/hotel/Hotel.vue";
import PaxDetail from "@/components/new-reservation-manuel/hotel/PaxDetail.vue";
import ContactInfo from "@/components/new-reservation-manuel/general/ContactInfo.vue";
import CurRateInfo from "@/components/new-reservation-manuel/general/CurRateInfo.vue";
import MasterValue from "@/components/new-reservation-manuel/general/MasterValue.vue";
import store from "@/store";


export default defineComponent({
    name: "manuel-reservation-master-value",
    components: {
        MasterValue,
        CurType,
        Hotel,
        PaxDetail,
        PaymentInfo,
        ContactInfo,
        CurRateInfo,

    },

    setup() {

        const isSubmit = ref<string>("false");
        const selectedHotel = ref<any>();
        const selectedRoomCount = ref<number>(1);

        const myMasterValue = computed(() => {
            return store.getters.makeReservationsDetails;
        });

        function formSubmitNew() {
            isSubmit.value = "true";
        };

        function mySelectedHotel(myValue) {
            selectedHotel.value = myValue
        };

        function myRoomCount(MyRoomValue) {
            selectedRoomCount.value = MyRoomValue
        };


        return {
            formSubmitNew,
            selectedRoomCount,
            isSubmit,
            myMasterValue,
            myRoomCount,
            mySelectedHotel,
            selectedHotel
        }
    },

});
