const reservationConfirmTypes2 = {
  "TYPES" : [
    {
      Value : 'CONFIRM',
      Title : 'Onay',
      BadgeName: 'success',
    },
    {
      Value : 'CHANGE',
      Title : 'Değişiklik',
      BadgeName: 'warning',
    },
    {
      Value : 'CANCEL',
      Title : 'İptal',
      BadgeName: 'danger',
    },

  ],
};
export default reservationConfirmTypes2;
