
import {defineComponent} from "vue";


export default defineComponent({
  name: "Reservation-Cur-Type",
  components: {
  },



});
