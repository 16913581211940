
import {defineComponent} from "vue";


export default defineComponent({
  name: "Choose-Hotel",
  components: {
  },



});
