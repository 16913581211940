
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ReservationTable from "@/components/reservations/List.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
    name: "hotel-settings",
    components: {
        ReservationTable,
    },
    props:{
        setValue: String,
        setCountry: String,
    },
    data: function () {
        return {
            myLoading: false,
        }
    },
    methods: {},
    watch: {
        /*
        setValue(e){
            this.myLoading = true;
            store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': e, 'Country': this.setCountry})
                .then(() => {
                    this.myList = store.getters.hotelList;
                    this.myLoading = false;
                    console.log("Actions.HOTEL_LIST_ALL "+JSON.stringify(this.myList));
                });
        },
        setCountry(e){
            this.myLoading = true;
            store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': this.setValue, 'Country': e})
                .then(() => {
                    this.myList = store.getters.hotelList;
                    this.myLoading = false;
                });
        }
         */
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Rezervasyon Listesi", ["Rezervasyon", "Liste"]);
        });
        store.dispatch(Actions.HOTEL_LIST_ALL, {'Title': 'C', 'Country': 'TR'});
        const myList = computed(() => {
            return store.getters.hotelList;
        });
        return {
            myList,
        };
    },
});
