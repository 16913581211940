

import {computed, defineComponent} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";




export default defineComponent({
  name: "manuel-reservation-cur-rate-info",
  components: {

  },
  data: function () {
    return {
    }
  },
  props: {
  },
  computed: {

  },
  methods: {

  },
  setup() {



    const payloadCRL = {
      Type: 'AUTO',
      validDate: dateConvert.method.getTodayYMD(),
      is_active: 'active',
      limit: 1,
    }

    store.dispatch(Actions.CUR_RATE_LIST, payloadCRL);
    const myCur = computed(() => {
      let tmpData = store.getters.curRateList;
      //if (tmpData.value !== undefined) newReservations.value.resCurTRY = "1";
      if (tmpData.data !== undefined) {
        return tmpData.data[0];
      } else {
        return {
          CurTRY: 1,
          CurUSD: 0,
          CurEUR: 0,
          CurSTG: 0,
        }
      }

    });


    return {
      myCur,
    }
  },
});
