

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import paymentMethods from "@/core/data/paymentMethods";
import curList from "@/core/data/currency";
import { Field} from "vee-validate";

interface paymentInfo {
  itemPaymentStatus: boolean;
  itemPaymentType: string;
  itemPaymentMethod: string;
  itemPaymentAmount: string;
  itemPaymentCur: string;
}

export default defineComponent({
  name: "manuel-reservation-payment-info",
  components: {
    Field,
  },
  data: function () {
    return {
    }
  },
  props: {
  },
  computed: {


  },
  methods: {

  },
  setup() {

    const paymentInfo = ref<paymentInfo>({
      itemPaymentStatus: true,
      itemPaymentType: "",
      itemPaymentMethod: "",
      itemPaymentAmount: "",
      itemPaymentCur: "",
    });

    store.dispatch(Actions.BANKPOS_LIST, {});
    const myBankPos = computed(() => {
      return store.getters.bankPosList;
    });

    store.dispatch(Actions.BANKACCOUNTS_LIST, {});
    const myBankAccount = computed(() => {
      return store.getters.bankAccountsList;
    });


    return {
      paymentMethods,
      curList,
      paymentInfo,
      myBankAccount,
      myBankPos,
    }
  },
});
