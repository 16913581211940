
import {defineComponent} from "vue";
export default defineComponent({  
    props: {idCode: String, items: {}, itemProjection:Function},
    setup(props, ctx) {        

        function keyupAutoComp() {
            let elAutoComp = document.getElementById("inpAutoComp_"+props.idCode) as HTMLInputElement;
            if (elAutoComp!==undefined && elAutoComp!==null) {
                ctx.emit('keyupAutoComp', elAutoComp.value);                                    
            }
        }

        function selectItemAutoComp(prmItem) {
            //console.log("selectItemAutoComp id: "+prmItem.id);
            ctx.emit('selectItem', prmItem);
        }

        return {keyupAutoComp, selectItemAutoComp};
    },
});

