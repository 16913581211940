import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "myth",
  id: "myth1"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_simple_typeahead = _resolveComponent("vue3-simple-typeahead")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue3_simple_typeahead, {
      id: `inpAutoComp_${_ctx.idCode}`,
      style: {"position":"static !important"},
      minInputLength: 3,
      items: _ctx.items,
      itemProjection: _ctx.itemProjection,
      onKeyup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.keyupAutoComp())),
      onSelectItem: _ctx.selectItemAutoComp
    }, {
      "list-item-text": _withCtx((slot) => [
        _createElementVNode("span", {
          innerHTML: slot.boldMatchText(slot.itemProjection(slot.item))
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["id", "items", "itemProjection", "onSelectItem"])
  ]))
}