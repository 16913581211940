

import {computed, defineComponent, ref} from "vue";
import store from "@/store";


interface contactInfo {
  resContactName: string;
  resContactPhone: string;
  resContactEmail: string;
}

export default defineComponent({
  name: "manuel-reservation-contact-info",
  components: {

  },
  data: function () {
    return {
    }
  },
  props: {
  },
  computed: {

  },
  methods: {

  },
  setup() {

    const contactInfo = ref<contactInfo>({
      resContactName: "",
      resContactPhone: "",
      resContactEmail: "",
    });




    return {
      contactInfo,
    }
  },
});
