

import {computed, defineComponent, ref, watch} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field} from "vee-validate";
import AutoComplete from "@/components/common/AutoComplete.vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


interface masterValue {
    resDepartment: string;
    resMarket: string;
    resAccount: string;
}

export default defineComponent({
    name: "manuel-reservation-master-value",
    components: {
        ErrorMessage,
        Field,
        AutoComplete,
    },
    data: function () {
        return {
        }
    },
    props: {
        idCode: String,
        isSubmit: String,
    },
    computed: {


    },
    methods: {
        getResults() {
            console.log("MAsterValue Get Results");
            let rv = this.confirmValues();
            return rv;
        }
    },
    setup(props) {

        watch(
            () => props.isSubmit,
            () => {
                if (props.isSubmit !== undefined) {

                    if(props.isSubmit=="true"){
                        let payload = {
                            DepartmentID: masterValue.value.resDepartment, Market: masterValue.value.resMarket,
                        }
                        store.dispatch(Actions.MAKE_MANUEL_RESERVATIONS, payload);
                    }
                }
            }
        );


        const masterValue = ref<masterValue>({
            resDepartment: "",
            resMarket: "",
            resAccount: "",
        });

        store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
        const myDepartments = computed(() => {
            return store.getters.DepartmentsList;
        });

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });


        let acAccountsKeyword = "";

        function keyupAutoCompAccounts(prmInputValue) {
            console.log("keyupAutoCompAccounts prmInputValue: "+prmInputValue+" len: "+prmInputValue.length+" acAccountsKeyword: "+acAccountsKeyword);
            if (prmInputValue.length == 3 && acAccountsKeyword != prmInputValue) {
                acAccountsKeyword = prmInputValue;
                if (prmInputValue.length == 3) {
                    store.dispatch(Actions.ACCOUNT_LIST_ALL, {'Title': prmInputValue});
                }

                masterValue.value.resAccount = "";
            }
        }

        function itemProjectionAccounts(prmItem) {
            return prmItem.name;
        }

        function selectItemAutoCompAccounts(prmItem) {
            masterValue.value.resAccount = prmItem.id;
            let payload = {AccountID: prmItem.id, is_active: "active"};
        }

        const lstAccounts = computed(() => {
            //console.log("store.getters.accountList " + JSON.stringify(store.getters.accountList));
            return(store.getters.accountList);
        });

        const lstAutoCompleteItemsAccounts = computed(() => {
            let arrTmp = [];
            if (lstAccounts.value !== undefined && lstAccounts.value.data !== undefined) {
                for (let each of lstAccounts.value.data) {
                    arrTmp.push({"id": each.AccountID, "name": each.Title});
                }
            }
            //console.log("arrTmp");
            //console.log(arrTmp);

            return arrTmp;
        });

        function confirmValues() {
            let rv = {isok : true, result:{},};

            if (rv.isok && masterValue.value.resDepartment==="") {
                libTools.method.swalError("Tamam", "Departman seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && masterValue.value.resAccount==="") {
                libTools.method.swalError("Tamam", "Hesap seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && masterValue.value.resMarket==="") {
                libTools.method.swalError("Tamam", "Masket seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                (rv.result as any).departmanId = masterValue.value.resDepartment ;
                (rv.result as any).accountId = masterValue.value.resAccount ;
                (rv.result as any).marketId = masterValue.value.resMarket ;
            }
            console.log("confirmValues END rv: "+JSON.stringify(rv));
            return rv;
        }

        return {
            myDepartments,
            masterValue,
            myMarkets,
            keyupAutoCompAccounts,
            itemProjectionAccounts,
            selectItemAutoCompAccounts,
            lstAutoCompleteItemsAccounts,
            confirmValues,
        }
    },
});
